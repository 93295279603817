<template>
  <div>
    <b-row>
      <b-col md="12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">App Modal</h3>
            </div>
            <div class="card-toolbar"></div>
          </div>
          <div class="card-body">
            <b-row>
              <b-col md="4">
                <b-row class="mb-4">
                  <b-col md="6">
                    <button class="btn btn-primary w-100" :disabled="is_loading_binance || is_loading_metamask" v-b-tooltip.hover @click="refreshModal" title="Klik untuk me-refresh modal dan saldo">
                      Refresh <b-icon-arrow-clockwise />
                    </button>
                  </b-col>
                  <b-col md="6">
                    <button
                      class="btn btn-info bg-red w-100"
                      :disabled="is_loading_binance || is_loading_metamask"
                      v-b-tooltip.hover
                      @click="updateModal"
                      title="Klik untuk mengupdate modal sebelumnya dengan modal sekarang"
                    >
                      Update <b-icon-clipboard-check />
                    </button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4">
                    <div class="p-1 py-3 rounded" style="background:#C9DAF8;text-align:center;">
                      <div class="mb-1" style="font-size:14px;">
                        SEBELUMNYA
                      </div>
                      <div style="font-size:24px;font-weight:bold;" v-html="sebelumnya.toFixed(2)"></div>
                      <div style="font-size:8px;font-weight:bold;">Rp. <span v-html="sebelumnya_idr || '-'"></span></div>
                    </div>
                  </b-col>
                  <b-col md="4">
                    <div class="p-1 py-3 rounded" style="background:#FFFF00;text-align:center;">
                      <div class="mb-1" style="font-size:14px;">
                        SEKARANG
                      </div>
                      <div style="font-size:24px;font-weight:bold;" v-html="error_binance != '' || error_metamask != '' ? '??' : sekarang.toFixed(2)"></div>
                      <div style="font-size:8px;font-weight:bold;">Rp. <span v-html="error_binance != '' || error_metamask != '' ? '??' : sekarang_idr || '-'"></span></div>
                    </div>
                  </b-col>
                  <b-col md="4">
                    <div class="p-1 py-3 rounded pnlbox" :class="{ profit: pnl > 0, loss: pnl < 0 }" style="text-align:center;">
                      <div class="mb-1" style="font-size:14px;">
                        PNL
                      </div>
                      <div style="font-size:24px;font-weight:bold;" v-html="error_binance != '' || error_metamask != '' ? '??' : pnl.toFixed(2)"></div>
                      <div style="font-size:8px;font-weight:bold;">Rp. <span v-html="error_binance != '' || error_metamask != '' ? '??' : pnl_idr || '-'"></span></div>
                    </div>
                  </b-col>
                </b-row>
                <h3 class="mt-5">Setting</h3>
                <b-form-group label="Wallet Address:" label-cols-sm="3">
                  <b-form-input v-model="wallet" type="text" size="sm" />
                </b-form-group>
                <b-form-group label="API Key:" label-cols-sm="3">
                  <b-form-input v-model="apikey" type="password" size="sm" />
                </b-form-group>
                <b-form-group label="Secret Key:" label-cols-sm="3">
                  <b-form-input v-model="secretkey" type="password" size="sm" />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <h4>
                  Aset Binance
                  <div v-if="is_loading_binance" class="spinner-border spinner-border-sm"></div>
                </h4>
                <b-row v-if="error_binance != ''">
                  <b-col md="12"><span class="badge badge-danger badge-xs">Error</span> {{ error_binance }}</b-col>
                </b-row>
                <b-row>
                  <b-col md="3" class="font-weight-bold"> BUSD </b-col> <b-col md="9">: {{ aset.binance_busd.toFixed(4) }} BUSD</b-col>
                </b-row>
                <b-row>
                  <b-col md="3" class="font-weight-bold"> USDT </b-col> <b-col md="9">: {{ aset.binance_usdt.toFixed(4) }} USDT</b-col>
                </b-row>
                <b-row>
                  <b-col md="3" class="font-weight-bold"> BNB </b-col> <b-col md="9">: {{ aset.binance_bnb.toFixed(8) }} BNB | {{ aset.binance_bnb_usdt.toFixed(4) }} USDT </b-col>
                </b-row>
                <b-row>
                  <b-col md="3" class="font-weight-bold"> TOTAL </b-col> <b-col md="9">: {{ aset.binance_total.toFixed(4) }} USD | Rp. {{ aset.binance_total_idr }} </b-col>
                </b-row>
                <h4>
                  Aset Metamask
                  <div v-if="is_loading_metamask" class="spinner-border spinner-border-sm"></div>
                </h4>
                <b-row v-if="error_metamask != ''">
                  <b-col md="12"><span class="badge badge-danger badge-xs">Error</span> {{ error_metamask }}</b-col>
                </b-row>
                <b-row>
                  <b-col md="3" class="font-weight-bold"> BUSD </b-col> <b-col md="9">: {{ aset.metamask_busd.toFixed(4) }} BUSD</b-col>
                </b-row>
                <b-row>
                  <b-col md="3" class="font-weight-bold"> USDT </b-col> <b-col md="9">: {{ aset.metamask_usdt.toFixed(4) }} USDT</b-col>
                </b-row>
                <b-row>
                  <b-col md="3" class="font-weight-bold"> BNB </b-col> <b-col md="9">: {{ aset.metamask_bnb.toFixed(8) }} BNB | {{ aset.metamask_bnb_usdt.toFixed(4) }} USDT </b-col>
                </b-row>
                <b-row>
                  <b-col md="3" class="font-weight-bold"> TOTAL </b-col> <b-col md="9">: {{ aset.metamask_total.toFixed(4) }} USD | Rp. {{ aset.metamask_total_idr }} </b-col>
                </b-row>
                <h4>Riwayat Update Modal*</h4>
                <table class="table table-bordered table-sm">
                  <tr>
                    <th>Waktu</th>
                    <th>Modal</th>
                    <th>PNL</th>
                  </tr>
                  <tr v-for="item in history.slice(-10).reverse()" :key="item.time">
                    <td>{{ item.time }}</td>
                    <td>{{ item.mdl.toFixed(4) }}</td>
                    <td>{{ item.pnl.toFixed(4) }}</td>
                  </tr>
                </table>

                *10 data terakhir
              </b-col>
              <b-col md="4">
                <h4>
                  Saldo Binance
                </h4>
                <table class="table table-bordered table-sm">
                  <tr>
                    <th>Koin</th>
                    <th>Saldo</th>
                    <th>Locked</th>
                  </tr>
                  <tr v-for="(koin, index) in saldo_binance" :key="index">
                    <td>{{ koin.asset }}</td>
                    <td>{{ koin.free }}</td>
                    <td>{{ koin.locked }}</td>
                  </tr>
                </table>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- modals -->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import CryptoService from "@/core/services/crypto.service";
import axios from "axios";

export default {
  data() {
    return {
      var_coba: "",
      awal: 0,
      sebelumnya: 0,
      sekarang: 0,
      pnl: 0,
      sebelumnya_idr: 0,
      sekarang_idr: 0,
      pnl_idr: 0,
      wallet: "",
      apikey: "",
      secretkey: "",

      aset: {
        binance_busd: 0,
        binance_usdt: 0,
        binance_bnb: 0,
        binance_bnb_usdt: 0,
        binance_total: 0,
        binance_total_idr: 0,
        metamask_busd: 0,
        metamask_usdt: 0,
        metamask_bnb: 0,
        metamask_bnb_usdt: 0,
        metamask_total: 0,
        metamask_total_idr: 0,
      },

      saldo_binance_raw: [],

      saldo_binance: [],

      is_loading_binance: false,
      is_loading_metamask: false,
      error_binance: "",
      error_metamask: "",

      history: [],
    };
  },
  computed: {
    ...mapGetters(["listBookTicker", "currentUser"]),
  },
  watch: {
    awal(newVal) {
      this.updateStored("awal", newVal);
    },
    wallet(newVal) {
      this.updateStored("wallet", newVal);
    },
    apikey(newVal) {
      this.updateStored("apikey", newVal);
    },
    secretkey(newVal) {
      this.updateStored("secretkey", newVal);
    },
  },
  components: {},
  methods: {
    get_url() {
      let timestamp = Date.now();
      let recvWindow = 15000;
      let qstring = "timestamp=" + timestamp + "&recvWindow=" + recvWindow;
      let signature = CryptoService.hmacsha256(this.secretkey, qstring);
      qstring = qstring + "&signature=" + signature;
      return "https://api.binance.me/api/v3/account?" + qstring;
    },
    refreshModal() {
      this.error_binance = "";
      this.error_metamask = "";
      this.refreshModalBinance();
    },
    refreshModalBinance() {
      this.is_loading_binance = true;
      const url = this.get_url();
      axios
        .get(url, {
          headers: {
            "X-MBX-APIKEY": this.apikey,
          },
        })
        .then(res => {
          this.is_loading_binance = false;
          this.saldo_binance_raw = res.data.balances;
          //saldo binance busd
          const b_busd = this.saldo_binance_raw.find(i => i.asset == "BUSD");
          const b_usdt = this.saldo_binance_raw.find(i => i.asset == "USDT");
          const b_bnb = this.saldo_binance_raw.find(i => i.asset == "BNB");
          this.aset.binance_busd = parseFloat(b_busd.free) + parseFloat(b_busd.locked);
          this.aset.binance_usdt = parseFloat(b_usdt.free) + parseFloat(b_usdt.locked);
          this.aset.binance_bnb = parseFloat(b_bnb.free) + parseFloat(b_bnb.locked);
          this.aset.binance_bnb_usdt = this.aset.binance_bnb * this.getBidPrice("BNBUSDT");
          this.aset.binance_total = this.aset.binance_busd + this.aset.binance_usdt + this.aset.binance_bnb_usdt;
          this.aset.binance_total_idr = Math.round(this.aset.binance_total * this.getBidPrice("USDTBIDR")).toLocaleString("ID");

          this.saldo_binance = this.saldo_binance_raw.filter(i => i.free > 0 || i.locked > 0);

          this.refreshModalMetamask();
        })
        .catch(error => {
          if (error.response) {
            this.error_binance = error.response.data;
          } else if (error.request) {
            this.error_binance = error.request;
          } else {
            this.error_binance = error.message;
          }
          this.is_loading_binance = false;
        });
    },
    refreshModalMetamask() {
      this.is_loading_metamask = true;
      axios
        .get(
          "https://api.bscscan.com/api?module=account&action=tokenbalance&contractaddress=0xe9e7cea3dedca5984780bafc599bd69add087d56&address=" +
            this.wallet +
            "&tag=latest&apikey=VTXAJJKJ7QUBPF8WYTQ36GD5UPM9W1J8CJ",
        )
        .then(res => {
          if (res.data.message == "OK") {
            this.aset.metamask_busd = this.decimalizer(res.data.result, 18);
            axios
              .get(
                "https://api.bscscan.com/api?module=account&action=tokenbalance&contractaddress=0x55d398326f99059ff775485246999027b3197955&address=" +
                  this.wallet +
                  "&tag=latest&apikey=VTXAJJKJ7QUBPF8WYTQ36GD5UPM9W1J8CJ",
              )
              .then(res => {
                if (res.data.message == "OK") {
                  this.aset.metamask_usdt = this.decimalizer(res.data.result, 18);
                  axios
                    .get("https://api.bscscan.com/api?module=account&action=balance&address=" + this.wallet + "&tag=latest&apikey=VTXAJJKJ7QUBPF8WYTQ36GD5UPM9W1J8CJ")
                    .then(res => {
                      if (res.data.message == "OK") {
                        this.aset.metamask_bnb = this.decimalizer(res.data.result, 18);
                        this.aset.metamask_bnb_usdt = this.aset.metamask_bnb * this.getBidPrice("BNBUSDT");
                        this.aset.metamask_total = this.aset.metamask_busd + this.aset.metamask_usdt + this.aset.metamask_bnb_usdt;
                        this.aset.metamask_total_idr = Math.round(this.aset.metamask_total * this.getBidPrice("USDTBIDR")).toLocaleString("ID");
                        this.is_loading_metamask = false;

                        this.sekarang = this.aset.binance_total + this.aset.metamask_total;
                        this.sekarang_idr = Math.round(this.sekarang * this.getBidPrice("USDTBIDR")).toLocaleString("ID");

                        this.pnl = this.sekarang - this.sebelumnya;
                        this.pnl_idr = Math.round(this.pnl * this.getBidPrice("USDTBIDR")).toLocaleString("ID");
                        this.updateStored("aset", this.aset);
                        this.updateStored("sekarang", this.sekarang);
                        this.updateStored("pnl", this.pnl);
                        this.updateStored("sebelumnya_idr", this.sebelumnya_idr);
                        this.updateStored("sekarang_idr", this.sekarang_idr);
                      } else {
                        this.is_loading_metamask = false;
                        this.error_metamask = JSON.stringify(res.data);
                      }
                    })
                    .catch(error => {
                      this.metamaskErrorCatcher(error);
                    });
                } else {
                  this.is_loading_metamask = false;
                  this.error_metamask = JSON.stringify(res.data);
                }
              })
              .catch(error => {
                this.metamaskErrorCatcher(error);
              });
          } else {
            this.is_loading_metamask = false;
            this.error_metamask = JSON.stringify(res.data);
          }
        })
        .catch(error => {
          this.metamaskErrorCatcher(error);
        });
    },
    updateModal() {
      if (this.pnl != 0) {
        let hist = localStorage.getItem("mw_hist_modal");
        if (hist == null) {
          hist = [];
        } else {
          hist = JSON.parse(hist);
        }
        var currentdate = new Date();
        var datetime =
          currentdate.getDate() + "/" + (currentdate.getMonth() + 1) + "/" + currentdate.getFullYear() + " " + currentdate.getHours() + ":" + currentdate.getMinutes() + ":" + currentdate.getSeconds();
        hist.push({
          time: datetime,
          mdl: this.sekarang,
          pnl: this.pnl,
        });
        this.history = hist;
        localStorage.setItem("mw_hist_modal", JSON.stringify(hist));
        this.sebelumnya = this.sekarang;
        this.sebelumnya_idr = this.sekarang_idr;
        this.pnl = 0;
        this.pnl_idr = 0;
        this.updateStored("sebelumnya", this.sebelumnya);
        this.updateStored("sebelumnya_idr", this.sebelumnya_idr);
        this.updateStored("pnl", this.pnl);
        this.updateStored("pnl_idr", this.pnl_idr);
      }
    },
    metamaskErrorCatcher(error) {
      if (error.response) {
        this.error_metamask = error.response.data;
      } else if (error.request) {
        this.error_metamask = error.request;
      } else {
        this.error_metamask = error.message;
      }
      this.is_loading_metamask = false;
    },
    updateStored(key, value) {
      if (localStorage.getItem("mw_modal") !== null) {
        let lst = JSON.parse(localStorage.getItem("mw_modal"));
        lst = { ...lst, [key]: value };
        localStorage.setItem("mw_modal", JSON.stringify(lst));
      } else {
        localStorage.setItem("mw_modal", JSON.stringify({ [key]: value }));
      }
    },
    decimalizer(value, decimal) {
      if (value.length < decimal) {
        return parseFloat("0." + value.padStart(decimal, "0"));
      } else {
        return parseFloat(value.substring(0, value.length - 18) + "." + value.substring(value.length - 18));
      }
    },
    getBidPrice(pair) {
      let data = this.listBookTicker.find(item => item.symbol == pair);
      if (data == undefined) {
        return 0;
      } else {
        return data.bidPrice;
      }
    },
  },
  mounted() {
    if (localStorage.getItem("mw_modal") !== null) {
      const lst = JSON.parse(localStorage.getItem("mw_modal"));

      this.awal = lst.awal || 0;
      this.sebelumnya = lst.sebelumnya || 0;
      this.sebelumnya_idr = lst.sebelumnya_idr || 0;
      this.sekarang = lst.sekarang || 0;
      this.sekarang_idr = lst.sekarang_idr || 0;
      this.pnl = lst.pnl || 0;
      this.pnl_idr = lst.pnl_idr || 0;
      this.wallet = lst.wallet || "";
      this.apikey = lst.apikey || "";
      this.secretkey = lst.secretkey || "";
      if (lst.aset != undefined) {
        this.aset = lst.aset;
      }
    }
    let hist = localStorage.getItem("mw_hist_modal");
    if (hist != null) {
      this.history = JSON.parse(hist);
    }
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Modal | Market Watch" }, { title: "App Modal" }]);
  },
};
</script>
